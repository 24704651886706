/* FONTS
Nunito Bold
font-family: nunito, sans-serif;
font-weight: 700;
font-style: normal;

font-family: nunito,sans-serif;
font-weight: 200;
font-style: normal;

Nunito Regular
font-family: nunito, sans-serif;
font-weight: 400;
font-style: normal;

Nunito Light
font-family: nunito, sans-serif;
font-weight: 300;
font-style: normal;

Nunito SemiBold
font-family: nunito, sans-serif;
font-weight: 600;
font-style: normal;

Nunito ExtraBold
font-family: nunito, sans-serif;
font-weight: 800;
font-style: normal;

Nunito Italic
font-family: nunito, sans-serif;
font-weight: 400;
font-style: italic;

Nunito Bold Italic
font-family: nunito, sans-serif;
font-weight: 700;
font-style: italic;
*/

/* rgba(214,13,43,0.6) */

$break-small: 600px;
$break-large: 1024px;
$break-x-large: 1200px;
$grey: #fbfbfb;
$red: #d60c2b;
$font: #484848;

#root > *, #root > * > *, .bannerImage {
	@media screen and (min-width: $break-x-large){
		max-width: 1200px;

	}

}

#root{
	max-width: auto;
	margin: 0 auto;
	display:flex;
	flex-direction: column;
}

#root > div {
	flex: 1 0 auto;
}

html,
body {
	color: $font !important;
	font-family: nunito, sans-serif !important;
	font-weight: 400;
	font-style: normal;
}

body {
	overflow-y: visible;
	overflow-x: hidden;
	
}

html{
	@media screen and (min-width: $break-x-large){
		display: flex;
		justify-content: center;
		align-items: center;
		margin-left: auto;
		margin-right: auto;

	}
}

#root {
	
	min-height: 100vh;

}

h1,
h2, h5 {
	font-family: nunito, sans-serif;
	font-weight: 300 !important;
	font-style: normal;
	margin-bottom: 2vh !important;
}

h5{
	font-size: 1.35rem !important
}

p {
	font-size: 1.1rem !important;
	font-weight: 300;
}

article {
	margin:5px 0px 25px 0px;

}

.HomeSubheading{
	margin-top: 40px;

}

.dropdownLink {
	color: #d60d2b;
}

.linkInheritColor {
	color: inherit !important
}

.strong {
	font-weight: 800;
}

/*Nav stuff*/
.navMain {
	background: inherit;
	color: white !important;
	z-index: 500;
	position: absolute !important;
	top: 0;
	right: 0;
	left: 0;
	z-index: 1030;
	padding-top: 2vh !important;
	padding-bottom: 2vh !important;
	margin:0 auto;

	@media screen and (max-width: 1100px) and (min-width: 990px){
		font-size: 0.9rem !important;
	}

	@media screen and (max-width: 990px){
		padding: 0px !important;
	}
}

.navbar-toggler{
	@media screen and (max-width: 990px){
		margin: 1rem 1rem;
	}
}

#navlink {
	color: white;
	padding-left: 1vw;
	padding-right: 1vw;
	padding-top: 0;
	padding-bottom: 0;
	font-size: 1.02rem !important;
	justify-self: center !important;
}

#navLink:hover {
	background-color: inherit !important;

}

.show {
	text-align: center !important;
}


.navIcons {
	font-size: 1.2rem !important;
}



.dropdown-menu.show {
	background-color: rgba(236, 236, 236, 0.199) !important;
	border: none !important;
	border-radius: 0px;
	font-size: 1.2rem !important;

	@media screen and (min-width: 991px) {
		background-color: rgba(255, 255, 255, 0.911) !important;
		border-radius: 5px;
	}
}

.navbar-nav *{
	@media screen and (max-width: 1060px) and (min-width: 990px){
		font-size: 0.9rem !important;

		
	}
}

.dropdown-menu.show > a {
	color: white !important;
	@media screen and (min-width: 991px) {
		color:rgba(51, 51, 51, 0.918) !important;
	}
}

.dropdown-item.active{
	background-color: rgba(0,0,0,0) !important
}


/*Mobile Nav stuff */

.navbar-toggler-icon, .navbar-toggler{
	border-color: rgba(0,0,0,0) !important;
	color: white !important;
}

.navbar-nav, .navbar-nav, .nav-link {
	color: white !important;
} 

.ml-auto, .navbar-nav {
	@media screen and (max-width: 993px){
		text-align:center !important;
		font-size: 1.3rem;
	};


	
}

.collapsing {
    transition: none !important;
}

/*Home page stuff */
.homeContainer {
	z-index: 501;
	width: 100vw;
	height: 65vh;
	padding: none !important;
	
	@media screen and (max-width: $break-small){
		
	}
}

.bannerImage {
	width: 100vw;
	height: auto;
	height: 40vh;
	object-fit: cover;
	object-position: 50% 65%;

	/*
	@media screen and (max-width: $break-large){
		height: 20vh !important;
	}
	*/
}

#aboutImage {
	object-position: 50% 45%;
}

.overlay {
	position: relative;
}

.overlay::after {
	position: absolute;
	content: "";
	top: 0;
	left: 0;
	width: 100vw;
	max-width: 1200px;
	height: 100%;
	background-color: #d60d2b;
	opacity: 0.6;
}

#homeLogo {
	position: absolute;
	justify-self: center;
	height: 27vh;
	z-index: 500;
	padding-top: 30px;

	@media screen and (max-width: $break-large) {
		height: 30vh;
	}

	@media screen and (max-width: $break-small) {
		height: 25vh;
	}
}

.homeMainText {
	font-size: 1.2rem !important;
}


#bannerh1 {
	position: absolute;
	justify-self: center;
	z-index: 500;
	color: white;
	font-family: nunito, sans-serif;
	font-weight: 200;
	font-style: normal;
	font-size: 4rem;
	text-align: center;

	@media screen and (max-width: $break-large){
		font-size: 3rem;
		text-align: center;
		padding-top: 1rem;
		max-width: 70vw;
	}
}

.parent {
	display: flex;
	justify-content: center;
	align-items: center;
}

.mainContainer {
	padding-top: 5vh;
	text-align: center;
	max-width: 65vw !important;

	@media screen and (max-width: $break-large) {
		min-width: 70vw !important;
	}

	@media screen and (max-width: $break-small) {
		min-width: 90vw !important;
	}

	@media screen and (min-width: $break-x-large){
		width: 590px !important;
	}
}

.homeButton {
	background-color: #d60d2b !important;
	color: white;
	margin-top: 2vh !important;
	margin-bottom: 1vh !important;
}

.homeButtonSecondary {
	background-color: #d60d2b !important;
	color: white;
	margin-top: 0 !important;
	margin-bottom: 1vh !important;
}

.homeCharityContainer{
	display:flex;
	flex-direction: row;
	justify-content: space-around;
	align-items: center;
	flex-wrap: wrap;
}

.homeCharityLogos{
	height:auto;
	max-height: 150px;
	min-height: 100px;
	object-fit: contain;
	flex-basis:22%;
	margin: 0px 5px 20px ;
	display: flex;
	align-items: center;
	justify-content: center;

	@media screen and (max-width: $break-small) {
		flex-basis:45%;
		padding:10px;
	}

}

.homeCharityLogos > img {
	height:auto;
	max-width:100%;

}

/*General stuff */
article {
	padding-bottom: 1vh;
	padding-top: 2vh;
}

.bodyContainer {
	width: 55vw !important;
	
	padding-top: 5vh;
	text-align: left;

	@media screen and (max-width: $break-large) {
		width: 80vw !important;
	}

	@media screen and (min-width: $break-large) {
		max-width: 650px !important;
	}


}

/*About page*/
#tickCol {
	display: flex;
	align-items: center;
	padding-bottom: 23px;
}

#tick {
	height: 2rem;
	text-align: center;
	vertical-align: middle;
}

#inclusionHeader {
	color: #d60d2b;
	font-weight: 600 !important;
	margin-bottom: 0;
}

#end {
	font-size: 1.3rem !important;
	line-height: 1.8rem;
}

/*Testimonials */
.testimonial-left {
	display: flex;
	flex-direction: row;
	align-items: center;
}
.testimonial-right {
	display: flex;
	flex-direction: row-reverse;
	align-items: center;
}

.testimonial-profile {
	height: 12vh;
	width: 12vh;
	object-fit: cover;
}

.testimonial-name {
	color: #d60d2b;
	font-weight: 800 !important;
}

.testimonial-inner {
	display: flex;
	flex-direction: column;
	padding-left: 1vw;
	padding-right: 1vw;

	@media screen and (max-width: $break-small) {
		padding-left: 4vw;
	padding-right: 4vw;
	}
}

.testimonial-inner p {
	margin-bottom: 0 !important;
	font-weight: 400;
}

.right {
	text-align: right;
}

/*Photos */
.mediaContainer {
	padding-top: 20px;

	display:flex;
	justify-content: center;

	@media screen and (max-width: $break-small){
		width: 100vw !important;
		padding-left: 0.5vw !important;
		padding-right: 0.5vw !important;
	}
}

.dynamicMediaRow {
	display: flex;
	flex-direction: row;
	flex-wrap: wrap;
	align-content: flex-start;
	justify-content: flex-start;
	max-width: 100vw;
	width:fit-content;

	align-items: stretch;
    box-sizing: border-box;
    flex-shrink: 0;
    position: relative;
}

.dynamicMediaRow > div {
	margin: 0px 4px 15px 4px;
	max-width:31.8%;
	flex: 1 0 31.8%;

	@media screen and (max-width: $break-small) {
		max-width:31.5%;
		margin: 0px 2px 15px 2px;
	}
	
} 

.card-img, .card-img-top {
	min-height:calc(width);
	object-fit: cover;

	@media screen and (max-width: $break-small){
		min-height: calc(100vw/3);

	}
	@media screen and (min-width: $break-small + 1) and  (max-width: $break-x-large){
		min-height: calc(100vw/3 - 30px);

	}
	@media screen and (min-width: $break-x-large){
		min-height: 350px;
	}
}

.modal-body{
	padding: 0px;
}


/*Videos */
.video {
	height: 70vh;
	width: 100%;
}

.videoModal > div{
	@media screen and (min-width: $break-large){
		min-width:85vw !important;
		//max-width:75vw !important;
	}

	@media screen and (max-width: $break-large){
		margin-left: 0 !important;
	}

	min-width:100vw;
	
}

.photo{
	max-width: 100%;
}

.videoCard {
	padding-left: 0 !important;
	padding-right: 0 !important;
	border: none !important;
	border-radius: 0px;
	box-shadow: 5px 4px 8px 0px rgba(0,0,0,0.16);

	@media screen and (min-width: $break-small) {
		max-width:30%
	}
	
}

.videoCardIcon{
	height: 35px;
    width: 35px;
    color: white;
    background-color: $red;
    border-radius: 9px;
    position: absolute;
    padding: 7px;
    top: 10px;
    left: 10px;

	@media screen and (max-width: $break-small) {
		width: 30px;
		height: 30px;
	}
}

.photoCard {
	padding-left: 0 !important;
	padding-right: 0 !important;
	border: none !important;
	border-radius: 0px;
	box-shadow: 5px 4px 8px 0px rgba(0,0,0,0.16);

}

.photoCard > img {
	width: 100%;
	max-width: 100% !important;
	height:100%;
	object-fit: cover;
}

.photoButton, .videoButton {
	width: 100%;
	height: 100%;

}

.card-deck{
	@media screen and (max-width: $break-large){
		display: block ;
		margin-bottom: 0px !important;
		
	}
}

.card-img-overlay{
	max-width: none !important;
	padding: 0 !important;
}

.card-body {
	background-color: $grey;
}

.emptyCard {
	border:none !important
}

.contactRow > .card-deck {
	margin-bottom: 20px;

}

.modal {
	overflow: none !important;
}

.modal-body {
	@media screen and (max-width: $break-small){
		padding: 0 !important;
	}
}

.galleryDivider{
	width: 92%;
	display: none;

	@media screen and (max-width: $break-small){
		display: block;
	}
}


/* Contact */

.contactRow {
	margin-left: 0 !important;
	margin-right: 0 !important;

}

.contactIcon {
	font-size:2.5rem !important;
	color: $red;
	margin-bottom: 0.5rem !important;
}

.contactBox {
	text-align: center;
	color: $font;
	background-color: $grey;
	box-shadow: 5px 4px 8px 0px rgba(0, 0, 0, 0.16);
	padding-top: 1rem !important;
	padding-bottom: 0.6rem !important;
}

#emailUs {
	@media screen and (max-width: $break-small) {
		margin-top: 2vh;
	}
}

.contactText {
	font-size: 1.6rem !important;
}

.bold {
	font-weight: 400 !important;
}

.socialMediaBox {
	margin-top: 2vh;
}

.contactContainer {
	@media screen and (min-width: $break-small + 1) {
		width: 50vw !important;
	}
}

/*Events*/
.noUpcomingEvents {
	margin-top: 30px !important;
	font-size: 22px !important;
	font-weight: 800;
	text-align: center;
}


/*Footer Stuff */
footer {
	bottom: 0;
	min-height: 8vh;
	
	//max-width: 100%;
	width: 100%;
	background-color: #d60d2b;
	margin-top: 5vh;
	color: white;
	text-align: center;
	align-items: center;
	padding-top: 2vh;
	padding-bottom: 2vh;
	justify-content: space-between;
	margin-right: 0 !important;
	margin-left: 0 !important;
	overflow: hidden;
	flex-shrink: 0;

	@media screen and (min-width: 990px){
		max-height:150px;
	}
}

.footerCol {
	align-items: center;
	@media screen and (max-width: $break-large){
		margin-bottom: 2vh;
	}

	@media screen and (max-width: $break-small) {
		width: 100% !important;
	}
}

.footerLogo {
	max-height: 100px;
	position: relative;
	left: 1vw;

	@media screen and (max-width: $break-large){
		max-height: 14vh;
		padding: 1vh;
	}
}

.footerIcons {
	color: white !important;
	font-size: 1.5rem;
	padding: 1.3rem;
	
}

.footerContainer{
	display: flex !important;
	flex-direction: row !important;
	justify-content: center !important;
	align-items: center !important;
	max-width: 1000px !important;
	padding: 0px 35px !important;

	@media screen and (max-width: $break-large){
		flex-direction: column !important;

	}
}

.johnLyonFooter {
	max-height: 110px ;

	@media screen and (max-width: $break-large){
		max-height: 14vh;
		padding: 1vh;
	}
}

.footSocial > p {
	display: flex;
	justify-content: center;

}

.footerColLeft {
	max-width:100% !important;

}